

















































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniInfoBox from '@/components/layout/SygniInfoBox.vue';
import moment from 'moment';
import { mapState } from 'vuex';
import { Prop, Watch } from 'vue-property-decorator';
import { OutputReportData } from '@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types';

@Component({
  components: { SygniSelect, SygniMultiSelect, SygniCheckbox, SygniRoundedButton, SygniContainerTitle, SygniInfoBox },
  computed: {
    ...mapState('regReporting', {
      newReportFormData: (state: any) => state.newReportFormData,
      fundData: (state: any) => state.fundData,
      outputReportData: (state: any) => state.outputReportData,
    })
  }
})
export default class ReportStep3 extends Vue {
  @Prop() isDisabled: boolean;
  newReportFormData!: any;
  fundData!: any;
  outputReportData!: OutputReportData;

  fakeData: any = {
    id: 'PLZASI00001',
    registerDate: '2017-01-19',
    terminationDate: ''
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get isAdmin() {
    return !!(this.activeUserData?.role?.regulatoryReporting === 'admin')
  }

  getRegistrationDate(dateString: string) {
    const date = moment(dateString);

    return date.format('YYYY-MM-DD');
  }

  getStartReportDate(item: any) {
    const momentRegisterASIDate = moment(item, 'YYYY-MM-DD');
    const { value: reportDate } = this.newReportFormData.reportYear;

    if (momentRegisterASIDate.isSameOrAfter(`${reportDate}-12-31`)) {
      return '-';
    }

    if (momentRegisterASIDate.isSameOrBefore(`${reportDate}-01-01`)) {
      return `${reportDate}-01-01`;
    }

    if (momentRegisterASIDate.isBetween(`${reportDate}-01-01`, `${reportDate}-03-31`, undefined, '[]')) {
      return `${reportDate}-04-01`;
    }

    if (momentRegisterASIDate.isBetween(`${reportDate}-04-01`, `${reportDate}-06-30`, undefined, '[]')) {
      return `${reportDate}-07-01`;
    }

    if (momentRegisterASIDate.isBetween(`${reportDate}-07-01`, `${reportDate}-09-30`, undefined, '[]')) {
      return `${reportDate}-10-01`;
    }

    if (momentRegisterASIDate.isSameOrAfter(`${reportDate}-10-01`)) {
      return '-';
    }
  }

  getEndReportDate(item: any) {
    const { value: reportDate } = this.newReportFormData.reportYear;

    if (item) {
      return this.$options.filters.dateWithoutTime(item);
    } else {
      return `${reportDate}-12-31`;
    }
  }

  getDate(item: any) {
    return this.$options.filters.dateWithoutTime(item)
  }

  openBalanceReport(optionName: string) {
    this.$store.commit('regReporting/setSelectedASIName', optionName);
    this.$emit('setNextStepBtnDisabled', false);
    this.$store.commit('regReporting/setCurrentStep', 5);
  }

  validateStep() {
    if(this.isDisabled) return;
    this.$emit('setNextStepBtnDisabled', false);
  }

  mounted() {
    this.validateStep();
  }

  @Watch('newReportFormData', { deep: true }) onFormDataChange() {
    this.validateStep();
  }
}
