





























































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import GenproxModal from "@/components/layout/GenproxModal.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import FundsTable from "@/modules/genprox/modules/AIFM/modules/dashboard/components/FundsTable.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import FundManagerCard from "@/modules/genprox/modules/AIFM/components/FundManagerCard.vue";
import ManagementTeam from "@/modules/genprox/modules/AIFM/components/ManagementTeamCard.vue";
import FundInformationCard from "@/modules/genprox/modules/AIFM/components/FundInformationCard.vue";
import ReportingRegulatoryCard
  from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import RegRepCard from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/RegRepCard.vue";
// eslint-disable-next-line no-unused-vars
import { Prop, Watch } from "vue-property-decorator";
import ReportStep1 from '@/modules/genprox/modules/fund/modules/reg-reporting/containers/report-form/ReportStep1.vue';
import ReportStep2 from '@/modules/genprox/modules/fund/modules/reg-reporting/containers/report-form/ReportStep2.vue';
import ReportStep3 from '@/modules/genprox/modules/fund/modules/reg-reporting/containers/report-form/ReportStep3.vue';
import ReportBalance from '@/modules/genprox/modules/fund/modules/reg-reporting/containers/report-form/ReportBalance.vue';
import ReportFileUploader from '@/modules/genprox/modules/fund/modules/reg-reporting/containers/report-form/ReportFileUploader.vue';
import { BTooltip } from 'bootstrap-vue';
import { mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import Utils from '@/modules/shared/utils/utils';
import { CreateRegRepDTO, ReportStatuses, UploadFileToReportDTO } from '@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types';
import { create, all } from 'mathjs'
import { NavigationGuardNext } from 'vue-router/types/router';
const math = create(all);

export interface Modal { 
  show: boolean, 
  title: string, 
  description: string 
}

@Component({
  components: {
    RegRepCard,
    SygniRoundedButton,
    ReportingRegulatoryCard,
    FundInformationCard,
    ManagementTeam,
    FundManagerCard,
    UserPresentation,
    FundsTable,
    SygniContainerTitle,
    SygniLoader,
    SygniCard,
    GenproxModal,
    ReportStep1,
    ReportStep2,
    ReportStep3,
    ReportBalance,
    BTooltip,
  },
  computed: {
    ...mapState('regReporting', {
      newReportFormData: (state: any) => state.newReportFormData,
      fundData: (state: any) => state.fundData,
      outputReportData: (state: any) => state.outputReportData,
      euroExchangeRates: (state: any) => state.euroExchangeRates,
    })
  }
})
export default class ReportForm extends Vue {
  @Prop() reportId: string | null;
  @Prop() reportStatus: string | null;
  @Prop() reports: any;
  btnNextStepDisabled: boolean = false;
  showValidationErrorModal: boolean = false;
  ReportStatuses: any = ReportStatuses;
  isLoading: boolean = false;
  newReportFormData!: any;
  fundData!: any;
  outputReportData!: any;
  euroExchangeRates!: any;
  balanceTotals: { assetsTotal: number, liabilitiesTotal: number } = {
    assetsTotal: 0,
    liabilitiesTotal: 0,
  };
  balance: { assets: any, liabilities: any, additionals: any } = {
    assets: [],
    liabilities: [],
    additionals: [],
  };
  infoModal: Modal = {
    show: false,
    title: 'Niepoprawny bilans',
    description: 'Niepoprawny bilans. Aktywa nie są równe pasywom.',
  };
  draftModal: Modal = {
    show: false,
    title: 'There are unsaved changes',
    description: 'Do you want to close this creator without saving draft report?'
  };
  showSubmitReportModalConfirmation: boolean = false
  isSubmitReportModalLoading: boolean = false
  nextRoute: NavigationGuardNext<Vue> = null;
  reRenderActions: number = 0

  get hasValidationError() {
    return this.newReportFormData?.currentStep === 3 ? !this.isReportReadyToCreate : this.btnNextStepDisabled
  }

  get validationErrorMessage() {
    return !this.isReportReadyToCreate && this.newReportFormData?.currentStep === 3 ? `Aby przejść do następnego kroku należy wgrać pliki dla wszystkich ASI, które mają raportowane dane.` : this.nextBtnTooltipMessage
  }

  get lastCompletedReport() {
    const completedReports: any = this.groupedReports[this.newReportFormData?.reportYear?.value]?.filter((report: any) => report.status?.toLowerCase() === 'completed')

    return completedReports?.length > 0 ? completedReports?.sort((a: any, b: any) => b?.correctionNumber - a?.correctionNumber)[0] : null
  }

  get exisitingReport() {
    const correctionNumber = this.newReportFormData?.reportType?.isFirstReport ? 0 : Number(this.newReportFormData?.reportType?.value)
    const year = Number(this.newReportFormData?.reportYear?.value)

    if (this.groupedReports[year]?.length) {
      const report = this.groupedReports[year]?.find((el: any) => el?.correctionNumber === correctionNumber) || null
      
      return {
        hasReport: !!(report),
        report
      }
    }

    return {
      hasReport: false,
      report: null
    }
  }

  get canProceed() {
    if (this.reportId) {
      return true
    }

    return !(this.exisitingReport?.hasReport && this.exisitingReport?.report?.id)
  }

  get groupedReports() {
    const reports: any[] = this.reports || []
    const groupedReports: any = {}

    reports.forEach((report: any) => {
      this.$set(groupedReports, report[0]?.year, report)
    })

    return groupedReports || []
  }

  get showDraftBtn() {
    if (!this.newReportFormData?.selectedASI?.length) {
      return false
    }

    if (this.isAdmin) {
      return !(this.reportStatus === ReportStatuses.GENERATED || this.reportStatus === ReportStatuses.APPROVED || this.reportStatus === ReportStatuses.COMPLETED || this.reportStatus === ReportStatuses.SENT_TO_ESPI)
    }

    return !this.isDisabled
  }

  get showUpdateReportBtn() {
    if (this.isAdmin) {
      return this.reportStatus === ReportStatuses.APPROVED
    }

    return false
  }

  get isDisabled() {
    if (!this.isAdmin) {
      if (this.reportStatus === ReportStatuses.APPROVED) {
        return true
      }
    }

    return (this.reportStatus === ReportStatuses.GENERATED || this.reportStatus === ReportStatuses.COMPLETED || this.reportStatus === ReportStatuses.SENT_TO_ESPI) ? true : false;
  }

  get euroRate() {
    if(this.euroExchangeRates[this.newReportFormData?.reportYear?.value]) {
      return this.euroExchangeRates[this.newReportFormData?.reportYear?.value];
    }

    return 4.5969;
  }

  get isDraft() {
    return this.reportId ? true : false;
  }

  get isAdmin() {
    return !!(this.activeUserData?.role?.regulatoryReporting === 'admin')
  }

  get nextBtnTooltipMessage() {
    switch (this.newReportFormData?.currentStep) {
      case 1:
        return this.btnNextStepDisabled ? 'Aby przejść do następnego kroku należy wpisać poprawny numer korekty.' : ''
      case 2:
        return this.btnNextStepDisabled ? 'Aby przejść do następnego kroku należy zaznaczyć wszystkie ASI, które powinny zostać zaraportowane.' : ''
      case 5:
        return this.btnNextStepDisabled ? 'Suma kapitału (funduszu) podstawowego dla 5 największych inwestorów nie może być pusta' : ''
      case 6:
        return this.btnNextStepDisabled ? 'Przejrzyj dokładnie dane, ponieważ wygląda na to, że brakuje jakichś wymaganych informacji' : ''
    }

    return ''
  }

  handleReportCreation(reportId: string) {
    this.$emit('reportDraftCreated', reportId)
  }

  setLoading(state: boolean) {
    this.isLoading = state
  }

  handleReportBtn() {
    if (!this.canProceed) {
      const reportStatus = this.exisitingReport?.report?.status
      if (reportStatus === ReportStatuses.DRAFT) {
        this.$emit('editDraftReport', this.exisitingReport?.report)
      }

      if (reportStatus === ReportStatuses.GENERATED || reportStatus === ReportStatuses.COMPLETED || reportStatus === ReportStatuses.SENT_TO_ESPI || reportStatus === ReportStatuses.APPROVED) {
        this.$emit('openReportPreview', this.exisitingReport?.report)
      }
    }
  }

  handleCloseBtn() {
    if(!this.showDraftBtn || this.reportStatus === ReportStatuses.GENERATED || (!this.isAdmin && this.reportStatus === ReportStatuses.APPROVED) || this.reportStatus === ReportStatuses.COMPLETED || this.reportStatus === ReportStatuses.SENT_TO_ESPI) {
      this.closeDraftModal(null, true);
    } else {
      this.openDraftModal(null);
    }
  }

  openDraftModal(next: NavigationGuardNext<Vue>) {
    this.draftModal.show = true;
    this.nextRoute = next;
  }

  closeValidationErrorModal() {
    this.showValidationErrorModal = false
  }

  async closeDraftModalAndSave() {
    this.draftModal.show = false;
    await this.saveDraft();
    this.closeForm();
    if(this.nextRoute) this.nextRoute();
  }

  closeDraftModal(nextRoute: any, closeForm: boolean = false) {
    this.draftModal.show = false;
    if(closeForm) this.closeForm();
    if(nextRoute) {
      nextRoute();
    }
  }

  async saveDraft() {
    this.isLoading = true;

    try {
      if (this.newReportFormData.currentStep == 5 || this.newReportFormData.currentStep == 6) {
        this.setSelectedASIBalance(false);
      }

      const data: CreateRegRepDTO = {
        fundIds: this.newReportFormData.selectedASI.map((el: any) => el.id),
        year: Number(this.newReportFormData.reportYear.value),
        correctionNumber: !this.newReportFormData.reportType.isFirstReport ? this.newReportFormData.reportType.value : 0,
        draft: true,
        data: JSON.stringify(this.newReportFormData),
      }

      if(this.reportId) {
        await this.$store.dispatch('regReporting/updateReporting', { data, reportId: this.reportId });
      } else {
        await this.$store.dispatch('regReporting/createReporting', data);
      }

      this.closeDraftModal(null);
      
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'New report has been successfully added'
      });
      this.$emit('reportCreated', data.year);

    } catch(err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isLoading = false;
  }

  getStartReportDate(item: any) {
    const momentRegisterASIDate = moment(item, 'YYYY-MM-DD');
    const { value: reportDate } = this.newReportFormData.reportYear;

    if (momentRegisterASIDate.isSameOrAfter(`${reportDate}-12-31`)) {
      return '';
    }

    if (momentRegisterASIDate.isSameOrBefore(`${reportDate}-01-01`)) {
      return `${reportDate}-01-01`;
    }

    if (momentRegisterASIDate.isBetween(`${reportDate}-01-01`, `${reportDate}-03-31`, undefined, '[]')) {
      return `${reportDate}-04-01`;
    }

    if (momentRegisterASIDate.isBetween(`${reportDate}-04-01`, `${reportDate}-06-30`, undefined, '[]')) {
      return `${reportDate}-07-01`;
    }

    if (momentRegisterASIDate.isBetween(`${reportDate}-07-01`, `${reportDate}-09-30`, undefined, '[]')) {
      return `${reportDate}-10-01`;
    }

    if (momentRegisterASIDate.isSameOrAfter(`${reportDate}-10-01`)) {
      return '';
    }
  }

  getEndReportDate(item: any) {
    const { value: reportDate } = this.newReportFormData.reportYear;

    if (item) {
      return this.$options.filters.dateWithoutTime(item);
    } else {
      return `${reportDate}-12-31`;
    }
  }

  getDate(dateString: string) {
    const date = moment(dateString);

    return date.format('YYYY-MM-DD');
  }

  getSortedAsiItems(asi: any) {
    let details: Array<any> = [];

    if (asi.balanceData?.assets.length) {
      asi.balanceData.assets.forEach((row: any) => {
        if (row.amount && row.details && row.includeInDetails) {
          details = details.concat(row.details);
        } else {
          if (row.amount) {
            details = details.concat([{
              id: details.length,
              name: row.name,
              market: '',
              type: null,
              isiiCode: '',
              subAssetType: row.subAssetType,
              value: this.roundToDecimal(row.amount),
            }])
          }
        }
      });
    }
    
    details = details.map((detail: any) => {
      detail = Utils.formatSubAssetType(detail)

      return detail
    })

    details = details.filter((el: any) => !(el?.value === undefined || el?.value === null))

    return details.sort((a, b) => b.value - a.value);
  }

  get sortedItems(): any {
    let items: any = [];

    this.newReportFormData.selectedASI.forEach((asi: any) => {
      items = items.concat(this.getSortedAsiItems(asi));
    });

    return items;
  }

  roundToInt(value: string) {
    return Math.round(parseFloat(value));
  }

  roundToDecimal(value: any) {
    value = +(Math.round(value + ("e+2" as unknown as Number)) + "e-2");
    return Number.isInteger(value) ? Math.round(value) : value.toString();
  }

  getMainInstrumentsTradedItems(asi: any) {
    const items = this.getSortedAsiItems(asi);

    return items
  }

  getPrincipalExposuresItems(asi: any) {
    let formattedItems: any = [];
    let items: any = this.getSortedAsiItems(asi);

    items = _.groupBy(items, 'subAssetType');

    for (const [key, value] of Object.entries(items)) {
      const aggregatedValueAmount = (value as Array<any>).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.bignumber(object.value))
      }, 0);

      formattedItems.push({
        id: 0,
        name: '',
        market: '',
        type: null,
        isiiCode: '',
        subAssetType: key,
        value: this.roundToDecimal(aggregatedValueAmount),
      });
    }
    
    return formattedItems.sort((a: any, b: any) => b.value - a.value);
  }

  getAifmPrincipalInstruments() {
    let formattedItems: any = [];
    let items: any = this.sortedItems;

    items = _.groupBy(items, 'subAssetType');

    for (const [key, value] of Object.entries(items)) {
      const aggregatedValueAmount = (value as Array<any>).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.bignumber(object.value))
      }, 0);

      formattedItems.push({
        id: 0,
        name: '',
        market: '',
        type: null,
        isiiCode: '',
        subAssetType: key,
        value: this.roundToDecimal(aggregatedValueAmount),
      });
    }

    return formattedItems.sort((a: any, b: any) => b.value - a.value);
  }

  getPortfolioConcentrationItems(asi: any) {
    let formattedItems: any = [];
    let items: any = this.getSortedAsiItems(asi);

    items = items.map((item: any) => {
      item.assetType = this.getAssetType(item?.subAssetType)
      
      if (!item.market) {
        item.market = 'Inne (rynek niepubliczny)'
      }

      return item
    })

    // group by asset type not sub asset type
    items = _.groupBy(items, (item: any) => {
      var props = ['assetType', 'market'], // server-defined
        prop = [];

      for (var i = 0, length = props.length; i < length; i++) {
        prop.push(item[props[i]]);
      }

      return prop.join('|');
    })

    for (const [key, value] of Object.entries(items)) {
      const aggregatedValueAmount = (value as Array<any>).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.bignumber(object.value))
      }, 0);

      formattedItems.push({
        id: key,
        name: '',
        market: (value as any)[0].market,
        type: null,
        isiiCode: '',
        subAssetType: (value as any)[0].subAssetType,
        value: this.roundToDecimal(aggregatedValueAmount),
      });
    }

    return formattedItems.sort((a: any, b: any) => b.value - a.value);
  }

  getAifPrincipalMarkets(asi: any) {
    let formattedItems: any = [];
    let mappedItems: any = [];
    let items: any = this.getSortedAsiItems(asi);

    mappedItems = _.cloneDeep(items).map((el: any) => {
      el.market = this.getMarketCode(el.market);

      return el;
    });

    mappedItems = _.groupBy(mappedItems, 'market');

    for (const [key, value] of Object.entries(mappedItems)) {
      const aggregatedValueAmount = (value as Array<any>).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.bignumber(object.value))
      }, 0);

      formattedItems.push({
        id: key,
        name: '',
        market: (value as any)[0].market,
        type: null,
        isiiCode: '',
        subAssetType: (value as any)[0].subAssetType,
        value: this.roundToDecimal(aggregatedValueAmount),
      });
    }

    return formattedItems.sort((a: any, b: any) => b.value - a.value);
  }

  getAifmPrincipalMarkets() {
    let formattedItems: any = [];
    let mappedItems: any = [];
    let items: any = this.sortedItems;

    mappedItems = _.cloneDeep(items).map((el: any) => {
      el.market = this.getMarketCode(el.market);

      return el;
    });

    mappedItems = _.groupBy(mappedItems, 'market');

    for (const [key, value] of Object.entries(mappedItems)) {
      const aggregatedValueAmount = (value as Array<any>).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.bignumber(object.value))
      }, 0);

      formattedItems.push({
        id: key,
        name: '',
        market: (value as any)[0].market,
        type: null,
        isiiCode: '',
        subAssetType: (value as any)[0].subAssetType,
        value: this.roundToDecimal(aggregatedValueAmount),
      });
    }

    return formattedItems.sort((a: any, b: any) => b.value - a.value);
  }

  getPositionByCode(array: Array<any>, code: string) {
    const position = array?.find((el: any) => el.code === code);
    return position ? position : '';
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get isDataMissing() {
    const selectedASIs = this.newReportFormData?.selectedASI
    let result: boolean = false

    selectedASIs.forEach((asi: any) => {
      if (asi?.reportData && !result) {
        if (!(asi?.balanceData && asi?.validated)) {
          result = true
        }
      }
    })

    return result
  }

  get nextBtnText() {
    // if(this.newReportFormData.currentStep == 6) return 'Generate report';

    // if(this.isDisabled) return this.newReportFormData.currentStep === 5 ? 'Go to summary' : 'Next step';

    // if(this.newReportFormData.currentStep == 3) return 'Next step';

    // return (this.newReportFormData.currentStep == 5) ? 'Add report' : 'Next step';

    if (this.isDisabled) return this.newReportFormData.currentStep === 6 ? 'Go to summary' : 'Next step';

    return this.newReportFormData.currentStep === 4 ? this.canGenerate ? 'Generate report' : 'Submit report' : 'Next step';
  }

  get canGenerate() {
    return this.isAdmin && this.reportStatus === 'approved'
  }

  get activeStepComponent() {
    switch (this.newReportFormData.currentStep) {
      case 1:
        return ReportStep1;
      case 2:
        return ReportStep2;
      case 3:
        return ReportFileUploader
      case 4:
        return ReportStep3;
      case 5:
      case 6:
        return ReportBalance;
      default:
        this.$store.commit('regReporting/resetCurrentStep');
        return ReportStep1;
    }
  }

  get isReportReadyToCreate(): boolean {
    let isReady = true;
    this.newReportFormData?.selectedASI?.filter((asi: any) => asi?.reportData)?.forEach((fund: any) => {
      if (fund?.files?.length === 0) {
        isReady = false
      }
    });
    return isReady;
  }

  updateTotals(totals: { assetsTotal: number, liabilitiesTotal: number }) {
    this.balanceTotals = totals;
  }

  updateBalanceAssets(items: any) {
    this.balance.assets = items;
  }

  updateBalanceLiabilities(items: any) {
    this.balance.liabilities = items;
  }

  updateBalanceAdditionals(items: any) {
    this.balance.additionals = items;
  }

  setNextStepBtnDisabled(val: boolean) {
    this.btnNextStepDisabled = val;
  }

  openSubmitReportModal() {
    this.showSubmitReportModalConfirmation = true
  }

  closeSubmitReportModal() {
    this.showSubmitReportModalConfirmation = false
  }

  scrollToTop() {
    if ((this.$refs?.reportForm as HTMLDivElement)?.scrollTop) {
      (this.$refs.reportForm as HTMLDivElement).scrollTop = 0
    }
  }

  previousStep() {
    if (this.newReportFormData.currentStep === 6) {
      this.setSelectedASIBalance(false, true)
    }

    this.$store.commit('regReporting/decrementCurrentStep');
  }

  setSelectedASIBalance(validated: boolean = false, saveOnly: boolean = false) {
    this.$store.commit('regReporting/setSelectedASIBalance', { asiName: this.$store.getters['regReporting/getSelectedASIName'], balance: _.cloneDeep(this.balance) })
    this.$store.commit('regReporting/setSelectedASIBalanceValidation', { asiName: this.$store.getters['regReporting/getSelectedASIName'], validated })
    if (!saveOnly) {
      this.$store.commit('regReporting/setCurrentStep', 4);
    }
  }

  async submitReport() {
    if (this.isDataMissing) {
      this.openSubmitReportModal()
    } else {
      await this.approveReport()
    }
  }

  async nextStep() {
    if (this.hasValidationError && this.validationErrorMessage) {
      this.showValidationErrorModal = true
      
      return;
    }

    if (this.newReportFormData.currentStep == 1) {
      const shouldImport = this.$store.getters['regReporting/getLoadLastCompletedReport']
      
      if (shouldImport) {
        const lastReportData = JSON.parse(this.lastCompletedReport?.data)
        const selectedAsis: string[] = lastReportData?.selectedASI?.map((el: any) => el.id)

        this.$store.commit('regReporting/setSelectedASIs', selectedAsis);

        lastReportData?.selectedASI?.forEach((asi: any) => {
          this.$store.commit('regReporting/setAsiBalance', { id: asi?.id, reportData: asi?.reportData, validated: asi?.validated, balanceData: asi?.balanceData })
        })
      }
    }

    // if (this.newReportFormData.currentStep == 6) {
    //   const files = await this.generateXmlFiles();

    //   files.forEach((file: any) => {
    //     Utils.downloadFileByUrl(Utils.createFileUrlFromContent(file) || file?.path, file?.fileName || file?.name)
    //   })

    //   return;
    // }

    if (this.newReportFormData.currentStep == 4) { // last step
      this.isLoading = true
      const data: CreateRegRepDTO = {
        fundIds: this.newReportFormData.selectedASI.map((el: any) => el.id),
        year: Number(this.newReportFormData.reportYear.value),
        correctionNumber: !this.newReportFormData.reportType.isFirstReport ? this.newReportFormData.reportType.value : 0,
        draft: false,
        data: JSON.stringify(this.newReportFormData),
      }

      if (this.canGenerate) {
        if (!this.btnNextStepDisabled) {
          const existingFiles: any = []

          if (this.reportId) {
            const report = await this.$store.dispatch('regReporting/getReportDataOnly', this.reportId)
  
            report?.funds?.forEach((fund: any) => {
              fund?.inputFiles?.forEach((file: any) => {
                existingFiles.push(file?.fileId)
              })
            })
          }

          // generate report
          try {
            if (this.reportId) {
              await this.$store.dispatch('regReporting/updateReporting', { data, reportId: this.reportId })
            } else {
              this.reportId = await this.$store.dispatch('regReporting/createReporting', data);
            }
            const files = await this.generateXmlFiles();
  
            const apiQuery = '/regulatory-reporting/knf-report-xml/upload';
            const fileRequests: Array<any> = [];
  
            files.forEach((file: any) => {
              fileRequests.push(this.$store.dispatch('regReporting/uploadXmlFile', { file, apiQuery }));
            });
  
            const results = await Promise.all(fileRequests);
  
            const reportRequests: Array<any> = [];
            results.forEach((result: any) => {
              reportRequests.push(this.$store.dispatch('regReporting/uploadOutputFile', {
                reportId: this.reportId,
                selfGenerated: true,
                fileId: result.id,
              }))
            });
  
            await Promise.all(reportRequests);

            const validationRequest: any[] = []
            // validate report files
            results.forEach((result: any) => {
              validationRequest.push(this.$store.dispatch('regReporting/validateXmlReport', result.id))
            })

            const validationResults: any = await Promise.all(validationRequest)
            const hasSchemaValidationErrors = validationResults?.filter((el: any) => el?.status === false)?.length
  
            // 1. upload report files
            const reportFileRequests: any[] = []
            this.newReportFormData?.selectedASI?.forEach((fund: any) => {
              fund?.files?.forEach((reportFile: any) => {
                if (!existingFiles.includes(reportFile.id)) {
                  const fileToReportDTO: UploadFileToReportDTO = {
                    payload: {
                      fileId: reportFile.id,
                      category: reportFile.category,
                      fundId: fund.id
                    },
                    reportId: this.reportId
                  }
                  reportFileRequests.push(this.$store.dispatch('regReporting/uploadReportFile', fileToReportDTO))
                }
              })
            })
  
            await Promise.all(reportFileRequests)
  
            // 2. create report
            await this.$store.dispatch('regReporting/generateReport', this.reportId);

            if (!hasSchemaValidationErrors) {
              this.$notify({
                type: 'success',
                title: 'Success',
                text: 'New report has been successfully added'
              });
            } else {
              this.$notify({
                type: 'warn',
                title: 'Partial success',
                text: 'New report has been successfully added however it looks like some generated xml files are containing errors',
                duration: 10000,
              })
            }

            this.$emit('reportCreated', data.year);
  
          } catch (e) {
            const errorMessage = this.$options.filters.errorHandler(e);
            this.$notify({
              type: 'error',
              title: 'An error occurred.',
              text: this.$t(errorMessage).toString()
            });
          }
        }
      } else {
        if (this.isDataMissing) {
          this.openSubmitReportModal()
        } else {
          await this.approveReport()
        }
      }
      
      this.isLoading = false;
      return;
    }
    
    if(this.newReportFormData.currentStep == 5) {
      const assetsWithAmount = this.balance.assets.filter((el: any) => {
        return el.includeInDetails;
      }).filter((el: any) => {
        return el.amount;
      });
      
      if(!this.isStepValidated(5)) {
        this.infoModal.title = 'Niepoprawny bilans';
        this.infoModal.description = 'Niepoprawny bilans. Aktywa nie są równe pasywom.';
        this.infoModal.show = true;
        return;
      }
      
      if (assetsWithAmount.length == 0) {
        this.setSelectedASIBalance(true);
        return;
      }

      // save asi balance only
      this.setSelectedASIBalance(false, true)
    }

    if(this.newReportFormData.currentStep == 6) {
      if(!this.isStepValidated(6)) {
        this.infoModal.title = 'Niepoprawne dane';
        this.infoModal.description = 'Niepoprawne dane. Któraś z nazw aktywów mogła pozostać pusta lub suma szczegółów nie jest równa wartości pozycji w jednym lub kilku wierszach.';
        this.infoModal.show = true;
        return;
      }

      this.setSelectedASIBalance(true);
      return;
    }

    this.$store.commit('regReporting/incrementCurrentStep');
  }

  async approveReport() {
    this.isSubmitReportModalLoading = true

    if (this.newReportFormData.currentStep == 5 || this.newReportFormData.currentStep == 6) {
      this.setSelectedASIBalance(false);
    }

    const data: CreateRegRepDTO = {
      fundIds: this.newReportFormData.selectedASI.map((el: any) => el.id),
      year: Number(this.newReportFormData.reportYear.value),
      correctionNumber: !this.newReportFormData.reportType.isFirstReport ? this.newReportFormData.reportType.value : 0,
      draft: this.showUpdateReportBtn ? true : false,
      data: JSON.stringify(this.newReportFormData),
    }

    try {
      const existingFiles: any = []

      if (this.reportId) {
        const report = await this.$store.dispatch('regReporting/getReportDataOnly', this.reportId)
  
        report?.funds?.forEach((fund: any) => {
          fund?.inputFiles?.forEach((file: any) => {
            existingFiles.push(file?.fileId)
          })
        })
      }

      if (this.reportId) {
        await this.$store.dispatch('regReporting/updateReporting', { data, reportId: this.reportId })
      } else {
        this.reportId = await this.$store.dispatch('regReporting/createReporting', data);
      }

      // 1. upload report files
      const reportFileRequests: any[] = []
      this.newReportFormData?.selectedASI?.forEach((fund: any) => {
        fund?.files?.forEach((reportFile: any) => {
          if (!existingFiles.includes(reportFile.id)) {
            const fileToReportDTO: UploadFileToReportDTO = {
              payload: {
                fileId: reportFile.id,
                category: reportFile.category,
                fundId: fund.id
              },
              reportId: this.reportId
            }
            reportFileRequests.push(this.$store.dispatch('regReporting/uploadReportFile', fileToReportDTO))
          }
        })
      })

      await Promise.all(reportFileRequests)

      if (this.reportStatus === ReportStatuses.APPROVED) {
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Report has been successfully updated'
        })
      } else {
        // 2. approve report
        await this.$store.dispatch('regReporting/approveReport', this.reportId);
        
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'New report has been successfully submitted'
        });
      }


      this.isSubmitReportModalLoading = false
      this.closeSubmitReportModal()
      this.$emit('reportCreated', data.year);
      // eslint-disable-next-line no-unreachable
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  closeForm() {
    this.$emit('closeForm');
  }

  getRowsWithValues(balance: any) {
    return balance.assets.filter((el: any) => {
      return el.amount ? true : false;
    })
  }

  isDetailInfoCorrect(rowDataItem: any) {
    let total = 0;
    const entireValue = math.bignumber(rowDataItem.amount);
    rowDataItem.details.forEach((el: any) => {
      total = math.add(math.bignumber(total), math.bignumber(el.value));
    });

    return math.number(total) == math.number(entireValue) ? true : false;
  }

  isStepValidated(stepNumber: number) {
    if (stepNumber == 5) {
      return (this.balanceTotals.assetsTotal === this.balanceTotals.liabilitiesTotal);
    }

    if(stepNumber == 6) {
      let isInfoCorrect = true;
      const rowsWithValues = this.getRowsWithValues(this.balance);
      
      rowsWithValues.forEach((rowItem: any) => {
        if(rowItem.includeInDetails) {
          if (!this.isDetailInfoCorrect(rowItem) || rowItem?.details?.find((el: any) => !el?.name)) {
            isInfoCorrect = false;
          }
        }
      })

      return isInfoCorrect;
    }
  }

  async generateXmlFiles() {
    const xmlFiles: Array<any> = [];

    for(let i = 1; i <= this.newReportFormData.selectedASI.length; i++) {
      const selectedASI = this.newReportFormData.selectedASI[i - 1];
      const fileName = `${this.outputReportData.aifmNumber}_${selectedASI.aifNumber}_DATAIF_${this.newReportFormData.reportYear.value}_${this.getReportingPeriodType(selectedASI.aifManagementStartDate, selectedASI.dateOfDiscontinuationOfAifManagement)}_${this.$options.filters.leadingZeroDigitFormat(this.newReportFormData.reportType.value, 3) }.xml`;
      xmlFiles.push({ fileName: fileName, fundId: selectedASI.id, content: await Utils.getInternalFilesBinaryContent(Utils.createFileUrlFromContent(this.generateDataifXml(selectedASI))) })
    }

    const aifmNumber = this.outputReportData.aifmNumber;
    const fileName = `${aifmNumber}_${aifmNumber}_DATMAN_${this.newReportFormData.reportYear.value}_${this.getReportingPeriodType(this.outputReportData.regulatoryRegistrationDate, this.outputReportData.regulatoryDeRegistrationDate)}_${this.$options.filters.leadingZeroDigitFormat(this.newReportFormData.reportType.value, 3)}.xml`;

    xmlFiles.push({ fileName: fileName, content: await Utils.getInternalFilesBinaryContent(Utils.createFileUrlFromContent(this.generateDatmanXml())) });

    const files: any = [];

    xmlFiles.forEach((el: any) => {
      files.push(new File([el.content], el.fileName, { type: 'text/xml' }));
    })

    return files;
  }

  getAssetMacroType(code: string) {
    if(!code) return '';

    return code.slice(0, 3);
  }

  getAssetType(code: string) {
    if(!code) return '';

    return code.slice(0, 7);
  }

  generateMainInstrumentsTraded(asi: any, totalValue: number) {
    let instruments = '';

    const items = this.getMainInstrumentsTradedItems(asi);

    const itemsTotal = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + this.roundToInt(this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(object.value)))))
    }, 0);

    const values: number[] = []

    if (items?.length <= 5 && itemsTotal != totalValue) {
      const difference = math.subtract(itemsTotal, totalValue)
      for (let index = 0; index < items?.length; index++) {
        if (index + 1 !== items?.length) {
          const value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          values.push(Number(value || 0))
        } else {
          let value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          value = math.number(math.subtract(Number(value), difference))
          values.push(value)
        }
      }
    }

    // x5 pozycji o najwyższej kwocie aktywów, posortowane od najwyższej do najniższej kwoty

    for (let index = 1; index <= 5; index++) {
      const isinIdentification = items[index - 1] && items[index - 1].isiiCode ? `
            <ISINInstrumentIdentification>${items[index - 1].isiiCode ? items[index - 1].isiiCode : ''}</ISINInstrumentIdentification>` : `       `;

      const restOfSchema = items[index - 1] ? `
            <SubAssetType>${items[index - 1].subAssetType}</SubAssetType>
            <InstrumentCodeType>${items[index - 1].isiiCode ? 'ISIN' : 'NONE'}</InstrumentCodeType>
            <InstrumentName>${this.$options.filters.encodeString(this.$options.filters.toPhrase(items[index - 1].name))}</InstrumentName>${isinIdentification}
            <PositionValue>${values[index - 1] ? this.roundToInt(values[index - 1]?.toString()) : this.roundToInt(items[index - 1].value) || 0}</PositionValue>
            <PositionType>L</PositionType>` : `
            <SubAssetType>NTA_NTA_NOTA</SubAssetType>`;

      instruments += `
          <MainInstrumentTraded>
            <Ranking>${index}</Ranking>${restOfSchema}
          </MainInstrumentTraded>`;
    }

    const mainInstrumentsTradedSchema = `<MainInstrumentsTraded>${instruments}
        </MainInstrumentsTraded>`;

    return mainInstrumentsTradedSchema;
  }

  getPercentage(value: any, total: any) {
    if(!value && !total) return 0;

    return this.roundToDecimal((this.roundToInt(value) / this.roundToInt(total)) * 100);
  }

  getMarketCode(market: string) {
    switch (market) {
      case 'Deutsche Börse AG':
        return 'XFRA';
      case 'Börse München':
        return 'XMUN';
      case 'Börse Duesseldorf - QUOTRIX':
        return 'XDUS';
      case 'Rynek amerykański':
        return 'DASH';
      case 'Giełda papierów wartościowych w Zagrzebiu':
        return 'XZAG';
      case 'Rynek giełdowy':
        return 'XWAR';
      case 'NewConnect':
        return 'XNCO';
      case 'OTC transactions':
        return 'OTC';
      case 'Euronext':
        return 'XAMS';
      default:
        return '';
    }
  }

  getMarketCodeType(market: string) {
    switch (market) {
      case 'Deutsche Börse AG':
      case 'Börse München':
      case 'Börse Duesseldorf - QUOTRIX':
      case 'Rynek amerykański':
      case 'Giełda papierów wartościowych w Zagrzebiu':
      case 'Rynek giełdowy':
      case 'NewConnect':
      case 'Euronext':
      case 'DASH':
      case 'XZAG':
      case 'XWAR':
      case 'XNCO':
      case 'XAMS':
      case 'XFRA':
      case 'XMUN':
      case 'XDUS':
        return 'MIC';
      case 'OTC transactions':
      case 'OTC':
        return 'OTC';
      case 'Inne (rynek niepubliczny)':
        return 'XXX';
      default:
        return 'XXX';
    }
  }

  generatePrincipalExposures(asi: any, totalValue: number) {
    let exposures = '';

    const items = this.getPrincipalExposuresItems(asi);

    const itemsTotal = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + this.roundToInt(this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(object.value)))))
    }, 0);

    const values: number[] = []
    const percentages: number[] = []
    const percentageTotal: number = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + Number(this.getPercentage(Number(object.value), totalValue))
    }, 0)

    if (items?.length <= 10) {
      const percentageDifference = math.number(math.bignumber(math.subtract(math.bignumber(percentageTotal), math.bignumber(100))))
      const difference = math.subtract(itemsTotal, totalValue)
      for (let index = 0; index < items?.length; index++) {
        if (index + 1 !== items?.length) {
          const value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          values.push(Number(value || 0))
          percentages.push(math.number(math.bignumber(this.getPercentage(Number(value || 0), totalValue))))
        } else {
          let value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          value = math.number(math.subtract(Number(value), difference))
          values.push(value)

          const percentage = math.number(math.bignumber(math.subtract(math.bignumber(this.getPercentage(math.bignumber(value || 0), math.bignumber(totalValue))), math.bignumber(percentageDifference))))
          percentages.push(percentage)
        }
      }
    }

    // x10 pozycji o najwyższej kwocie aktywów, posortowane od najwyższej do najniższej kwoty

    for (let index = 1; index <= 10; index++) {
      const restOfSchema = items[index - 1] ? `
            <AssetMacroType>${this.getAssetMacroType(items[index - 1].subAssetType)}</AssetMacroType>
            <SubAssetType>${items[index - 1].subAssetType}</SubAssetType>
            <PositionType>L</PositionType>
            <AggregatedValueAmount>${values[index - 1] ? this.roundToInt(values[index - 1]?.toString()) : this.roundToInt(items[index - 1].value) || 0}</AggregatedValueAmount>
            <AggregatedValueRate>${percentages[index - 1] ? this.roundToDecimal(percentages[index - 1]?.toString()) : this.getPercentage(values[index - 1] ? this.roundToInt(values[index - 1]?.toString()) : this.roundToInt(items[index - 1].value) || 0, itemsTotal)}</AggregatedValueRate>` : `
            <AssetMacroType>NTA</AssetMacroType>`;

      exposures += `
          <PrincipalExposure>
            <Ranking>${index}</Ranking>${restOfSchema}
          </PrincipalExposure>`;
    }

    const principalExposuresSchema = `<PrincipalExposures>${exposures}
        </PrincipalExposures>`;

    return principalExposuresSchema;
  }

  getTypicalPositionSize(asi: any) {
    let typicalPositionSize = 'V_SMALL';
    let items: any = [];

    if (asi.balanceData?.assets.length) {
      asi.balanceData.assets.forEach((row: any) => {
        if (row.details && row.includeInDetails) {
          items = items.concat(row.details);
        }
      });
    }

    items = items.sort((a: any, b: any) => b.value - a.value);

    const maxValue = math.number(math.bignumber(items[0]?.value ? math.bignumber(items[0]?.value) : 0));

    if (maxValue >= math.number(math.bignumber(22984500)) && maxValue < math.number(math.bignumber(114922500))) {
      typicalPositionSize = 'SMALL';
    } else if (maxValue >= math.number(math.bignumber(114922500)) && maxValue < math.number(math.bignumber(689535000))) {
      typicalPositionSize = 'LOW_MID_MKT';
    } else if (maxValue >= math.number(math.bignumber(689535000)) && maxValue < math.number(math.bignumber(2298450000))) {
      typicalPositionSize = 'UP_MID_MKT';
    } else if (maxValue >= math.number(math.bignumber(2298450000)) && maxValue < math.number(math.bignumber(4596900000))) {
      typicalPositionSize = 'L_CAP';
    } else if (maxValue >= math.number(math.bignumber(4596900000))) {
      typicalPositionSize = 'M_CAP';
    }

    return typicalPositionSize;
  }

  generatePortfolioConcentrations(asi: any, totalValue: number) {
    let concentrations = '';

    const items = this.getPortfolioConcentrationItems(asi);

    const itemsTotal = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + this.roundToInt(this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(object.value)))))
    }, 0);

    const values: number[] = []
    const percentages: number[] = []
    const percentageTotal: number = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + Number(this.getPercentage(Number(object.value), totalValue))
    }, 0)

    if (items?.length <= 5) {
      const percentageDifference = math.number(math.bignumber(math.subtract(math.bignumber(percentageTotal), math.bignumber(100))))
      const difference = math.subtract(itemsTotal, totalValue)
      for (let index = 0; index < items?.length; index++) {
        if (index + 1 !== items?.length) {
          const value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          values.push(Number(value || 0))
          percentages.push(math.number(math.bignumber(this.getPercentage(Number(value || 0), totalValue))))
        } else {
          let value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          value = math.number(math.subtract(Number(value), difference))
          values.push(value)

          const percentage = math.number(math.bignumber(math.subtract(math.bignumber(this.getPercentage(math.bignumber(value || 0), math.bignumber(totalValue))), math.bignumber(percentageDifference))))
          percentages.push(percentage)
        }
      }
    }

    // x5 pozycji o najwyższej kwocie aktywów, posortowane od najwyższej do najniższej kwoty

    for(let index = 1; index <= 5; index++) {
      const marketCodeType = items[index - 1] ? this.getMarketCodeType(items[index - 1].market) : 'NONE';
      const marketCode = items[index - 1] && marketCodeType === 'MIC' ? `
                <MarketCode>${this.getMarketCode(items[index - 1].market)}</MarketCode>` : ``;

      const restOfSchema = items[index - 1] ? `
              <AssetType>${this.getAssetType(items[index - 1].subAssetType)}</AssetType>
              <PositionType>L</PositionType>
              <MarketIdentification>
                <MarketCodeType>${marketCodeType}</MarketCodeType>${marketCode}
              </MarketIdentification>
              <AggregatedValueAmount>${values[index - 1] ? this.roundToInt(values[index - 1]?.toString()) : this.roundToInt(items[index - 1].value) || 0}</AggregatedValueAmount>
              <AggregatedValueRate>${percentages[index - 1] ? this.roundToDecimal(percentages[index - 1]?.toString()) : this.getPercentage(values[index - 1] ? this.roundToInt(values[index - 1]?.toString()) : this.roundToInt(items[index - 1].value) || 0, itemsTotal)}</AggregatedValueRate>` : `
              <AssetType>NTA_NTA</AssetType>`;

      concentrations += `
            <PortfolioConcentration>
              <Ranking>${index}</Ranking>${restOfSchema}
            </PortfolioConcentration>`;
    }

    const portfolioConcentrations = `<PortfolioConcentrations>${concentrations}
          </PortfolioConcentrations>`;

    return portfolioConcentrations;
  }

  generateAifPrincipalMarkets(asi: any, totalValue: number) {
    let markets = '';

    const items = this.getAifPrincipalMarkets(asi);

    const itemsTotal = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + this.roundToInt(this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(object.value)))))
    }, 0);

    const values: number[] = []

    if (items?.length <= 3 && itemsTotal != totalValue) {
      const difference = math.subtract(itemsTotal, totalValue)
      for (let index = 0; index < items?.length; index++) {
        if (index + 1 !== items?.length) {
          const value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          values.push(Number(value || 0))
        } else {
          let value = this.roundToDecimal(math.number(math.bignumber(this.roundToDecimal(items[index]?.value || 0))))
          value = math.number(math.subtract(Number(value), difference))
          values.push(value)
        }
      }
    }

    // x3 pozycji o najwyższej kwocie aktywów, posortowane od najwyższej do najniższej kwoty

    for(let index = 1; index <= 3; index++) {
      const marketCodeType = items[index - 1] ? this.getMarketCodeType(items[index - 1].market) : 'XXX';
      const marketCode = items[index - 1] && marketCodeType === 'MIC' ? `
                <MarketCode>${items[index - 1].market}</MarketCode>` : ``;
      
      const restOfSchema = items[index - 1] ? `
              <MarketIdentification>
                <MarketCodeType>${marketCodeType}</MarketCodeType>${marketCode}
              </MarketIdentification>
              <AggregatedValueAmount>${values[index - 1] ? this.roundToInt(values[index - 1]?.toString()) : this.roundToInt(items[index - 1].value) || 0}</AggregatedValueAmount>` : `
              <MarketIdentification>
                <MarketCodeType>NOT</MarketCodeType>
              </MarketIdentification>`;
      
      markets += `
            <AIFPrincipalMarket>
              <Ranking>${index}</Ranking>${restOfSchema}
            </AIFPrincipalMarket>`;
    }

    const aifPrincipalMarkets = `<AIFPrincipalMarkets>${markets}
          </AIFPrincipalMarkets>`;

    return aifPrincipalMarkets;
  }

  generateAifmPrincipalMarkets(totalValue: number) {
    let markets = '';

    const items = this.getAifmPrincipalMarkets();

    const itemsTotal = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + this.roundToInt(this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(object.value)), this.euroRate))))
    }, 0);

    const values: number[] = []

    if (items?.length <= 5 && itemsTotal != totalValue) {
      const difference = math.subtract(itemsTotal, totalValue)
      for (let index = 0; index < items?.length; index++) {
        if (index + 1 !== items?.length) {
          const value = this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(items[index].value)), this.euroRate))) 
          values.push(Number(value || 0))
        } else {
          let value = this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(items[index].value)), this.euroRate))) 
          value = math.number(math.subtract(Number(value), difference))
          values.push(value)
        }
      }
    }

    // x5 pozycji o najwyższej kwocie aktywów, posortowane od najwyższej do najniższej kwoty

    for (let index = 1; index <= 5; index++) {
      const marketCodeType = items[index - 1] ? this.getMarketCodeType(items[index - 1].market) : 'XXX';
      const marketCode = items[index - 1] && marketCodeType === 'MIC' ? `
            <MarketCode>${items[index - 1].market}</MarketCode>` : ``;
      const value = items[index - 1] && values[index - 1] ? values[index - 1] : this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(items[index - 1]?.value || 0)), this.euroRate))) || '0';
      
      const restOfSchema = items[index - 1] ? `
          <MarketIdentification>
            <MarketCodeType>${marketCodeType}</MarketCodeType>${marketCode}
          </MarketIdentification>
          <AggregatedValueAmount>${this.roundToInt(value)}</AggregatedValueAmount>` : `
          <MarketIdentification>
            <MarketCodeType>NOT</MarketCodeType>
          </MarketIdentification>`;
      
      markets += `
        <AIFMFivePrincipalMarket>
          <Ranking>${index}</Ranking>${restOfSchema}
        </AIFMFivePrincipalMarket>`;
    }

    const aifmPrincipalMarkets = `<AIFMPrincipalMarkets>${markets}
      </AIFMPrincipalMarkets>`;

    return aifmPrincipalMarkets;
  }

  generateAifmPrincipalInstruments(totalValue: number) {
    let instruments = '';

    const items = this.getAifmPrincipalInstruments();
    
    const itemsTotal = _.cloneDeep(items).reduce((accumulator: any, object: any) => {
      return accumulator + this.roundToInt(this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(object.value)), this.euroRate))))
    }, 0);

    const values: number[] = []
    
    if (items?.length <= 5 && itemsTotal != totalValue) {
      const difference = math.subtract(itemsTotal, totalValue)
      for (let index = 0; index < items?.length; index++) {
        if (index + 1 !== items?.length) {
          const value = this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(items[index]?.value || 0)), this.euroRate)))
          values.push(Number(value || 0))
        } else {
          let value = this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(items[index]?.value || 0)), this.euroRate)))
          value = math.number(math.subtract(Number(value), difference))
          values.push(value)
        }  
      }
    }

    // x5 pozycji o najwyższej kwocie aktywów, posortowane od najwyższej do najniższej kwoty

    for (let index = 1; index <= 5; index++) {
      const value = items[index - 1] && values[index - 1] ? values[index - 1] : this.roundToDecimal(math.number(math.divide(math.bignumber(this.roundToDecimal(items[index - 1]?.value || 0)), this.euroRate))) || '0';
      const restOfSchema = items[index - 1] ? `
          <SubAssetType>${items[index - 1].subAssetType}</SubAssetType>
          <AggregatedValueAmount>${this.roundToInt(value.toString())}</AggregatedValueAmount>` : `
          <SubAssetType>NTA_NTA_NOTA</SubAssetType>`;
      instruments += `
        <AIFMPrincipalInstrument>
          <Ranking>${index}</Ranking>${restOfSchema}
        </AIFMPrincipalInstrument>`
    }

    const aifmPrincipalInstruments = `<AIFMPrincipalInstruments>${instruments}
      </AIFMPrincipalInstruments>`;

    return aifmPrincipalInstruments;
  }

  getReportingPeriodStartMonth(date: string) {
    const selectedDate = moment(date);
    let reportingPeriodStartDateMonth = 0;

    if (selectedDate.year() == this.newReportFormData.reportYear.value) {
      switch (selectedDate.month()) {
        case 0:
        case 1:
        case 2:
          reportingPeriodStartDateMonth = 3;
          break;
        case 3:
        case 4:
        case 5:
          reportingPeriodStartDateMonth = 6;
          break;
        case 6:
        case 7:
        case 8:
          reportingPeriodStartDateMonth = 9;
          break;
        default:
          reportingPeriodStartDateMonth = 0;
          selectedDate.add(1, 'years');
          break;
      }
    }
    
    return reportingPeriodStartDateMonth;
  }
  
  getReportingPeriodStartDate(date: string): string {
    const selectedDate = moment(date);
    selectedDate.set('month', this.getReportingPeriodStartMonth(date));
    
    return selectedDate.format('YYYY-MM-01');
  }

  getReportingPeriodEndDate(date?: string) {
    const selectedDate = moment(date);

    return date ? selectedDate.format('YYYY-MM-DD') : `${this.newReportFormData.reportYear.value}-12-31`;
  }

  getReportingPeriodType(startDate: string, endDate: string): string {
    const reportingYear = moment(new Date(this.newReportFormData.reportYear.value));
    const start = moment(new Date(startDate));
    const end = endDate ? moment(new Date(endDate)) : null;

    const startQuarter = start?.quarter();
    const endQuarter = end?.quarter();

    if(!end) {
      if(start < reportingYear) {
        return 'Y1';
      } else {
        if(startQuarter === 1) {
          return 'X2';
        } else if(startQuarter === 2) {
          return 'H2';
        } else if(startQuarter === 3) {
          return 'Q4';
        }
      }
    } else {
      if(start < reportingYear) {
        if(endQuarter === 1) {
          return 'Q1';
        } else if(endQuarter === 2) {
          return 'H1';
        } else if(endQuarter === 3) {
          return 'X1';
        } else {
          return 'Y1';
        }
      } else {
        if(startQuarter === 1) {
          if(endQuarter === 1) {
            return 'Q1';
          } else if(endQuarter === 2) {
            return 'Q2';
          } else if(endQuarter === 3) {
            return '?';
          } else {
            return 'X2';
          }
        } else if(startQuarter === 2) {
          if(endQuarter === 2) {
            return 'Q2';
          } else if(endQuarter === 3) {
            return 'Q3';
          } else if(endQuarter === 4) {
            return 'H2';
          }
        } else if(startQuarter === 3) {
          if(endQuarter === 3) {
            return 'Q3';
          } else if(endQuarter === 4) {
            return 'Q4';
          }
        }
      }
    }

    return 'Y1';
  }

  generateDatmanXml() {
    const fillingType = (this.newReportFormData.reportType.isFirstReport) ? 'INIT' : 'AMND';
    const assetsTotal = this.roundToDecimal(this.sortedItems.reduce((accumulator: any, object: any) => {
      return accumulator + math.number(math.bignumber(object.value))
    }, 0));

    const amountInEuro = this.roundToInt(math.number(math.divide(math.number(math.bignumber(assetsTotal)), this.euroRate)).toString());
    
    const restOfSchema = `
    <AIFMCompleteDescription>
      <AIFMIdentifier />
      ${this.generateAifmPrincipalMarkets(amountInEuro)}
      ${this.generateAifmPrincipalInstruments(amountInEuro)}
      <AUMAmountInEuro>${amountInEuro.toString()}</AUMAmountInEuro>
      <AIFMBaseCurrencyDescription>
        <BaseCurrency>PLN</BaseCurrency>
        <AUMAmountInBaseCurrency>${this.roundToInt(math.number(math.multiply(math.number(math.bignumber(amountInEuro)), this.euroRate)).toString()).toString()}</AUMAmountInBaseCurrency>
        <FXEURReferenceRateType>ECB</FXEURReferenceRateType>
        <FXEURRate>${this.euroRate.toString()}</FXEURRate>
      </AIFMBaseCurrencyDescription>
    </AIFMCompleteDescription>`;

    const reportingPeriodStartDate = this.getStartReportDate(this.outputReportData.regulatoryRegistrationDate);
    const reportingPeriodEndDate = this.getEndReportDate(this.outputReportData.regulatoryDeRegistrationDate);

    const shouldReport = this.newReportFormData.selectedASI?.filter((asi: any) => asi?.reportData)?.length ? true : false

    const xmlSchema = `<?xml version="1.0" encoding="UTF-8"?>
<AIFMReportingInfo xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" CreationDateAndTime="${moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')}" Version="1.2" ReportingMemberState="PL" xsi:noNamespaceSchemaLocation="AIFMD_DATMAN_V1.2.xsd">
  <AIFMRecordInfo>
    <FilingType>${fillingType}</FilingType>
    <AIFMContentType>2</AIFMContentType>
    <ReportingPeriodStartDate>${reportingPeriodStartDate}</ReportingPeriodStartDate>
    <ReportingPeriodEndDate>${reportingPeriodEndDate}</ReportingPeriodEndDate>
    <ReportingPeriodType>${this.getReportingPeriodType(this.outputReportData.regulatoryRegistrationDate, this.outputReportData.regulatoryDeRegistrationDate)}</ReportingPeriodType>
    <ReportingPeriodYear>${this.newReportFormData.reportYear.value}</ReportingPeriodYear>
    <LastReportingFlag>${this.outputReportData?.regulatoryDeRegistrationDate ? 'true' : 'false'}</LastReportingFlag>
    <AIFMReportingCode>1</AIFMReportingCode>
    <AIFMJurisdiction>PL</AIFMJurisdiction>
    <AIFMNationalCode>${this.outputReportData.aifmNumber}</AIFMNationalCode>
    <AIFMName>${this.$options.filters.encodeString(this.outputReportData.aifmName)}</AIFMName>
    <AIFMEEAFlag>true</AIFMEEAFlag>
    <AIFMNoReportingFlag>${shouldReport ? 'false' : 'true'}</AIFMNoReportingFlag>${shouldReport ? restOfSchema : ''}
  </AIFMRecordInfo>
</AIFMReportingInfo>`;

    return xmlSchema;
  }

  generateAumFocus(countries: any[], asiItems: any[], totalValue: number) {
    let aumFocus: any = {}

    let sum: number = 0;
    for (let i = 1; i <= countries.length; i++) {
      const country = countries[i - 1];
      const countryItems = asiItems?.filter((el: any) => el.country === country)
      const countryItemsValue = _.cloneDeep(countryItems).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.bignumber(object.value))
      }, 0)

      aumFocus[country] = math.number(math.round(math.bignumber(math.divide(math.number(math.multiply(math.number(countryItemsValue), 100)), math.number(totalValue))), 2))
      sum = math.add(sum, aumFocus[country])

      // if (i === countries?.length) {
      //   const remaining = math.number(math.subtract(math.bignumber(100), math.bignumber(sum)))
      //   aumFocus[country] = remaining
      // } else {
      //   aumFocus[country] = math.number(math.round(math.bignumber(math.divide(math.number(math.multiply(math.number(countryItemsValue), 100)), math.number(totalValue))), 2))
      //   sum = math.add(sum, aumFocus[country])
      // }
    }

    // check and recalculate
    const remaining = math.number(math.subtract(math.bignumber(100), math.bignumber(sum)))

    if (aumFocus['Europa (EOG)']) {
      aumFocus['Europa (EOG)'] = math.number(math.add(math.bignumber(aumFocus['Europa (EOG)']), math.bignumber(remaining)))
    } else {
      aumFocus['Europa (EOG)'] = remaining
    }

    return aumFocus
  }

  generateNavFocus(countries: any[], asiItems: any[], totalValue: number) {
    const navFocus: any = {}

    let sum: number = 0;
    for (let i = 1; i <= countries.length; i++) {
      const country = countries[i - 1];
      const countryItems = asiItems?.filter((el: any) => el.country === country)

      const countryItemsValue = _.cloneDeep(countryItems).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.bignumber(object.value))
      }, 0)

      navFocus[country] = math.number(math.round(math.bignumber(math.divide(math.number(math.multiply(math.number(countryItemsValue), 100)), math.number(totalValue))), 2))
      sum = math.add(sum, navFocus[country])

      // if (i === countries?.length) {
      //   const remaining = math.number(math.subtract(math.bignumber(100), math.bignumber(sum)))
      //   navFocus[country] = remaining
      // } else {
      //   navFocus[country] = math.number(math.round(math.bignumber(math.divide(math.number(math.multiply(math.number(countryItemsValue), 100)), math.number(totalValue))), 2))
      //   sum = math.add(sum, navFocus[country])
      // }
    }

    // check and recalculate
    const remaining = math.number(math.subtract(math.bignumber(100), math.bignumber(sum)))

    if (navFocus['Europa (EOG)']) {
      navFocus['Europa (EOG)'] = math.number(math.add(math.bignumber(navFocus['Europa (EOG)']), math.bignumber(remaining)))
    } else {
      navFocus['Europa (EOG)'] = remaining
    }

    return navFocus
  }

  generateDataifXml(asi: any) {
    const fillingType = (this.newReportFormData.reportType.isFirstReport) ? 'INIT' : 'AMND';
    const aifNetAssetValue = this.getPositionByCode(asi.balanceData?.liabilities, 'A.');
    const aifNetObligationsValue = this.getPositionByCode(asi.balanceData?.liabilities, 'B.')
    const mainBeneficialOwnersRate = this.getPercentage(asi.balanceData?.additionals[1]?.amount, asi.balanceData?.additionals[0]?.amount);

    const assetsTotal = _.cloneDeep(this.getSortedAsiItems(asi)).reduce((accumulator: any, object: any) => {
      return accumulator + math.number(math.bignumber(object.value))
    }, 0);

    const asiItems = this.getSortedAsiItems(asi)?.filter((el: any) => el.market)
    const asiTotalValue = _.cloneDeep(asiItems).reduce((accumulator: any, object: any) => {
      return accumulator + math.number(math.bignumber(object.value))
    }, 0)

    const countries = _.uniq(asiItems?.map((el: any) => el.country))

    if (!countries?.includes('Europa (EOG)')) {
      countries.push('Europa (EOG)')
    }

    const aifNetValues = math.add(aifNetAssetValue?.amount || 0, aifNetObligationsValue?.amount || 0)

    const aumFocus: any = this.generateAumFocus(countries, asiItems, assetsTotal)
    const navFocus: any = this.generateNavFocus(countries, asiItems, asiTotalValue <= aifNetAssetValue?.amount ? aifNetAssetValue?.amount : aifNetValues)
    
    const restOfSchema = `
    <AIFCompleteDescription>
      <AIFPrincipalInfo>
        <AIFIdentification />
        <ShareClassFlag>false</ShareClassFlag>
        <AIFDescription>
          <AIFMasterFeederStatus>NONE</AIFMasterFeederStatus>
          <AIFBaseCurrencyDescription>
            <BaseCurrency>PLN</BaseCurrency>
            <AUMAmountInBaseCurrency>${this.roundToInt(assetsTotal)}</AUMAmountInBaseCurrency>
            <FXEURReferenceRateType>ECB</FXEURReferenceRateType>
            <FXEURRate>${this.euroRate.toString()}</FXEURRate>
          </AIFBaseCurrencyDescription>
          <AIFNetAssetValue>${aifNetAssetValue ? this.roundToInt(aifNetAssetValue.amount) : '0'}</AIFNetAssetValue>
          <FirstFundingSourceCountry>PL</FirstFundingSourceCountry>
          <PredominantAIFType>PEQF</PredominantAIFType>
          <PrivateEquityFundInvestmentStrategies>
            <PrivateEquityFundInvestmentStrategy>
              <PrivateEquityFundStrategyType>VENT_CAPL</PrivateEquityFundStrategyType>
              <PrimaryStrategyFlag>true</PrimaryStrategyFlag>
              <StrategyNAVRate>100</StrategyNAVRate>
            </PrivateEquityFundInvestmentStrategy>
          </PrivateEquityFundInvestmentStrategies>
          <HFTTransactionNumber>0</HFTTransactionNumber>
          <HFTBuySellMarketValue>0</HFTBuySellMarketValue>
        </AIFDescription>
        ${this.generateMainInstrumentsTraded(asi, Number(this.roundToInt(assetsTotal)))}
        <NAVGeographicalFocus>
          <AfricaNAVRate>${countries?.includes('Afryka') ? navFocus['Afryka'] : '0'}</AfricaNAVRate>
          <AsiaPacificNAVRate>${countries?.includes('Azja i Pacyfik (inne niż Bliski Wschód)') ? navFocus['Azja i Pacyfik (inne niż Bliski Wschód)'] : '0'}</AsiaPacificNAVRate>
          <EuropeNAVRate>${countries?.includes('Europa (poza EOG)') ? navFocus['Europa (poza EOG)'] : '0'}</EuropeNAVRate>
          <EEANAVRate>${countries?.includes('Europa (EOG)') ? navFocus['Europa (EOG)'] : countries?.length ? '0' : '100'}</EEANAVRate>
          <MiddleEastNAVRate>${countries?.includes('Bliski Wschód') ? navFocus['Bliski Wschód'] : '0'}</MiddleEastNAVRate>
          <NorthAmericaNAVRate>${countries?.includes('Ameryka Północna') ? navFocus['Ameryka Północna'] : '0'}</NorthAmericaNAVRate>
          <SouthAmericaNAVRate>${countries?.includes('Ameryka Południowa') ? navFocus['Ameryka Południowa'] : '0'}</SouthAmericaNAVRate>
          <SupraNationalNAVRate>${countries?.includes('Wiele regionów') ? navFocus['Wiele regionów'] : '0'}</SupraNationalNAVRate>
        </NAVGeographicalFocus>
        <AUMGeographicalFocus>
          <AfricaAUMRate>${countries?.includes('Afryka') ? aumFocus['Afryka'] : '0'}</AfricaAUMRate>
          <AsiaPacificAUMRate>${countries?.includes('Azja i Pacyfik (inne niż Bliski Wschód)') ? aumFocus['Azja i Pacyfik (inne niż Bliski Wschód)'] : '0'}</AsiaPacificAUMRate>
          <EuropeAUMRate>${countries?.includes('Europa (poza EOG)') ? aumFocus['Europa (poza EOG)'] : '0'}</EuropeAUMRate>
          <EEAAUMRate>${countries?.includes('Europa (EOG)') ? aumFocus['Europa (EOG)'] : countries?.length ? '0' : '100'}</EEAAUMRate>
          <MiddleEastAUMRate>${countries?.includes('Bliski Wschód') ? aumFocus['Bliski Wschód'] : '0'}</MiddleEastAUMRate>
          <NorthAmericaAUMRate>${countries?.includes('Ameryka Północna') ? aumFocus['Ameryka Północna'] : '0'}</NorthAmericaAUMRate>
          <SouthAmericaAUMRate>${countries?.includes('Ameryka Południowa') ? aumFocus['Ameryka Południowa'] : '0'}</SouthAmericaAUMRate>
          <SupraNationalAUMRate>${countries?.includes('Wiele regionów') ? aumFocus['Wiele regionów'] : '0'}</SupraNationalAUMRate>
        </AUMGeographicalFocus>
        ${this.generatePrincipalExposures(asi, Number(this.roundToInt(assetsTotal)))}
        <MostImportantConcentration>
          ${this.generatePortfolioConcentrations(asi, Number(this.roundToInt(assetsTotal)))}
          <TypicalPositionSize>${this.getTypicalPositionSize(asi)}</TypicalPositionSize>
          ${this.generateAifPrincipalMarkets(asi, Number(this.roundToInt(assetsTotal)))}
          <InvestorConcentration>
            <MainBeneficialOwnersRate>${mainBeneficialOwnersRate ? this.roundToDecimal(mainBeneficialOwnersRate) : '0'}</MainBeneficialOwnersRate>
            <ProfessionalInvestorConcentrationRate>100</ProfessionalInvestorConcentrationRate>
            <RetailInvestorConcentrationRate>0</RetailInvestorConcentrationRate>
          </InvestorConcentration>
        </MostImportantConcentration>
      </AIFPrincipalInfo>
    </AIFCompleteDescription>`;

    const reportingPeriodStartDate = this.getStartReportDate(asi.aifManagementStartDate);
    const reportingPeriodEndDate = this.getEndReportDate(asi.dateOfDiscontinuationOfAifManagement);

    const xmlSchema = `<?xml version="1.0" encoding="UTF-8"?>
<AIFReportingInfo xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ReportingMemberState="PL" Version="1.2" CreationDateAndTime="${moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')}" xsi:noNamespaceSchemaLocation="AIFMD_DATAIF_V1.2.xsd">
  <AIFRecordInfo>
    <FilingType>${fillingType}</FilingType>
    <AIFContentType>3</AIFContentType>
    <ReportingPeriodStartDate>${reportingPeriodStartDate}</ReportingPeriodStartDate>
    <ReportingPeriodEndDate>${reportingPeriodEndDate}</ReportingPeriodEndDate>
    <ReportingPeriodType>${this.getReportingPeriodType(asi.aifManagementStartDate, asi.dateOfDiscontinuationOfAifManagement)}</ReportingPeriodType>
    <ReportingPeriodYear>${this.newReportFormData.reportYear.value}</ReportingPeriodYear>
    <LastReportingFlag>${asi?.dateOfDiscontinuationOfAifManagement ? 'true' : 'false'}</LastReportingFlag>
    <AIFMNationalCode>${this.outputReportData.aifmNumber}</AIFMNationalCode>
    <AIFNationalCode>${asi.aifNumber}</AIFNationalCode>
    <AIFName>${this.$options.filters.encodeString(asi.name)}</AIFName>
    <AIFEEAFlag>true</AIFEEAFlag>
    <AIFReportingCode>1</AIFReportingCode>
    <AIFDomicile>PL</AIFDomicile>
    <InceptionDate>${this.getDate(asi.aifManagementStartDate)}</InceptionDate>
    <AIFNoReportingFlag>${asi.reportData ? 'false' : 'true'}</AIFNoReportingFlag>${asi.reportData ? restOfSchema : ''}
  </AIFRecordInfo>
</AIFReportingInfo>`;

    return xmlSchema;
  }

  @Watch('newReportFormData.currentStep', { immediate: true }) onCurrentStepUpdate() {
    this.scrollToTop()
  }
}

